import React from 'react';
import PropTypes from 'prop-types';
import ChildRoutes from 'helpers/ChildRoutes';
import { getTenants } from 'utils/misc';

const ChildrenRoutes = ({ admin, locale, route, tenantId, user }) => {
  const userAllowedTenants = user ? getTenants(user.contracts) : [];

  return (
    <div style={{ margin: '20px', minHeight: '65vh' }}>
      <ChildRoutes
        isAdmin={!!admin}
        isUser={user !== null}
        locale={locale}
        routes={route.routes}
        tenantId={tenantId}
        userAllowedTenants={userAllowedTenants}
      />
    </div>
  );
};

ChildrenRoutes.propTypes = {
  admin: PropTypes.object,
  locale: PropTypes.string,
  route: PropTypes.object,
  tenantId: PropTypes.string,
  user: PropTypes.object,
};

export default ChildrenRoutes;
