import { setAuthCookie, setTrustedDeviceCookie } from 'utils/cookies/setCookies';
import { Notification } from 'redux/middleware/notificationMiddleware';
import isAdmin from 'utils/auth/isAdmin';
import { refresh } from './actions';
import types from './types';
import { add } from '../notifications';
import { push } from '../router';

const { SET_USER, LOGIN_SUCCESS, LOGOUT_SUCCESS, REFRESH_SUCCESS, RESEND_SUCCESS, VERIFY_SUCCESS } = types;
/*
  This middleware monitors the verify 2FA endpoint and set trusted device to cookies whenever trusted device info is returned.
*/
let timer = null;

const REDIRECT_LINK = '/financial/currentStatus';

const getRedirectUrl = query => {
  if (!query.nextURL) {
    return REDIRECT_LINK;
  }

  const decodedURL = decodeURIComponent(query.nextUrl);
  // If decodeURIComponent is unable to decode anything, it will return 'undefined' as string
  if (decodedURL !== 'undefined') {
    return decodedURL;
  }

  return query.nextURL;
};

export default ({ dispatch, getState }) => next => action => {
  const { result, type } = action;

  const dispatchRefresh = () => {
    // Use the Session Token TTL to set the timeout to call the refresh token action
    // The TTL is in seconds, convert it to milliseconds and reduce 30 seconds to avoid timing issues
    const idleTimeOut = (result.ttl - 30) * 1000;
    timer = setTimeout(() => dispatch(refresh()), idleTimeOut);
  };

  switch (type) {
    case LOGIN_SUCCESS: {
      next(action); // trigger the next action to update the state first
      // Handling admin 2FA authentication
      // Redirect to 2FA verify page if there was previously no 2FA request ID and no 2FA options and now there is, but there is still no user token.
      if (result.twoFactorRequestId || result.auth2faOptions) {
        dispatch(push('/verify2faCode'));
      }
      break;
    }
    case REFRESH_SUCCESS: {
      if (!result?.sendTwoFactorCode) {
        // The session already started if no 2FA request ID was sent
        setAuthCookie(result);
        dispatchRefresh();
      }
      break;
    }
    case LOGOUT_SUCCESS: {
      // Clear the timer if the user is logged out
      clearTimeout(timer);
      break;
    }
    case VERIFY_SUCCESS: {
      next(action); // trigger the next action to update the state first

      const { user, trustedDevice } = result;

      if (trustedDevice) {
        setTrustedDeviceCookie(trustedDevice);
      }

      setAuthCookie(result);
      dispatchRefresh();
      const isUserAdmin = isAdmin(user);
      const { auth, router } = getState();

      if (auth.admin || isUserAdmin) {
        // Redirect admin to choose an owner ID to begin the session
        dispatch(push('/selectOwnerId'));
      }
      else {
        const { location } = router;
        // Auto redirect user to the path if defined in the URL
        const nextURL = getRedirectUrl(location.query);
        dispatch(push(nextURL));
      }
      break;
    }
    case SET_USER: {
      // An admin user logged in and selected an owner
      const { router } = getState();
      const { location } = router;
      // Auto redirect user to the path if defined in the URL
      const nextURL = getRedirectUrl(location.query);
      dispatch(push(nextURL));
      break;
    }
    case RESEND_SUCCESS: {
      if (result.twoFactorRequestGenerated) {
        dispatch(add(new Notification('notifications.resend2faCode.successful', type)));
      }
      break;
    }
    default: {
      next(action);
      return;
    }
  }
  next(action);
};
