import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import { IntlProvider } from 'react-intl-redux';
import { Button, Modal, Segment, Sidebar } from 'semantic-ui-react';
import OutsideClickHandler from '@htmniseko/htm-components/OutsideClickHandler';
import { close } from '@htmniseko/htm-ui/DatePicker';
import { logout } from 'redux/modules/auth';
import { hide } from 'redux/modules/dialog';
import DatePicker from 'components/DatePicker/DatePicker';
import Dialog from 'components/Dialog/Dialog';
import Footer from 'components/Footer/Footer';
import Loader from 'components/Common/Loader';
import { ClientManager, Header, MobileSidebar, NavigationBar, NotificationManager, Popup } from 'containers';
import { useDispatch, useSelector } from 'react-redux';
import ChildrenRoutes from './ChildrenRoutes';
import IdleTimer from './IdleTimer';
import constants from '../../constants';
import 'fomantic-ui/dist/semantic.min.css'; // eslint-disable-line import/extensions

const App = ({ route }) => {
  const dispatch = useDispatch();
  const { admin, user } = useSelector(state => state.auth);
  const dialog = useSelector(state => state.dialog);
  const { loaderVisible } = useSelector(state => state.app);
  const { open: isOpen } = useSelector(state => state.datepicker);
  const { screenType } = useSelector(state => state.client);
  const tenant = useSelector(state => state.tenant);
  const locale = useSelector(state => state.intl.locale);

  const [displaySidebar, setDisplaySidebar] = useState(false);
  const [isReady, setIsReady] = useState(false);

  const idleTimer = useRef(null);

  const { title, text, messageType, visible } = dialog;
  const { head: { titleTemplate, meta } } = tenant.config;

  const showSidebar = screenType === 'tablet' || screenType === 'mobile';

  useEffect(() => {
    setIsReady(true);
  }, []);

  useEffect(() => {
    if (idleTimer.current && (user || admin)) {
      // Manually start the timer only after a user has logged in
      // Don't start the timer automatically neither when the component mounts because a page refresh will trigger a timeout and logged out the user
      idleTimer.current.start();
    }
  }, [user, admin]);

  const onIdle = () => {
    // End session if the user is idle
    if (user) {
      dispatch(logout());
    }
  };

  const toggleSidebar = () => {
    setDisplaySidebar(!displaySidebar);
  };

  const closeSidebar = () => {
    setDisplaySidebar(false);
  };

  const hideDialog = () => {
    dispatch(hide());
  };

  const closeModal = () => {
    dispatch(close());
  };

  return (
    <IntlProvider>
      <Helmet titleTemplate={titleTemplate} meta={meta} />
      <IdleTimer
        ref={idleTimer}
        crossTab
        timeout={constants.idleTimeout}
        syncTimers
        onIdle={() => onIdle()}
      />
      { (!isReady || loaderVisible) && <Loader />}
      { isReady && (
        <>
          {/* Menu sidebar for mobile view */}
          <OutsideClickHandler onOutsideClick={closeSidebar}>
            <MobileSidebar visible={displaySidebar} closeSidebar={closeSidebar} />
          </OutsideClickHandler>
          {
            // Floating mobile menu icon
            showSidebar && user && (
              <Button
                basic
                circular
                icon="sidebar"
                onClick={() => toggleSidebar()}
                style={{ zIndex: 5, position: 'fixed', top: '10px', left: '10px' }}
              />
            )
          }
          <Sidebar.Pushable as={Segment} style={{ border: 0, borderRadius: 0, minHeight: '100vh', margin: 0 }}>
            {/* Module for updating screen size information */}
            <ClientManager />
            {/* Datepicker */}
            <Modal open={isOpen} onClose={() => closeModal()} size="mini">
              <Modal.Content style={{ maxWidth: '350px', margin: '0 auto' }}>
                <DatePicker />
              </Modal.Content>
            </Modal>
            {/* Module for displaying notifications */}
            <NotificationManager />
            {/* Module for displaying pop-up message */}
            <Popup />
            {/* Module for displaying message in a full screen dialog */}
            <Dialog
              open={visible}
              title={title}
              message={text}
              type={messageType}
              onClose={() => hideDialog()}
            />
            {/* Main content */}
            <Sidebar.Pusher style={{ position: 'initial' }}>
              { !showSidebar && <Header /> }
              { !showSidebar && user && <NavigationBar /> }
              {/* Child routes */}
              <ChildrenRoutes
                admin={admin}
                route={route}
                locale={locale}
                tenantId={tenant.id}
                user={user}
              />
              { !showSidebar && <Footer /> }
            </Sidebar.Pusher>
          </Sidebar.Pushable>
        </>
      )}
    </IntlProvider>
  );
};

App.propTypes = {
  route: PropTypes.object,
};

export default App;
